@tailwind base;
@tailwind components;
@tailwind utilities;

@import "/src/assets/webfonts/font-face.css";
@import "/src/assets/webfonts/css/clash-display.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Aspekta", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebebeb;
}

h1 {
  font-family: "Clash Display", "Aspekta", "Inter", "sans-serif";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-btn {
  @apply px-6 py-2.5 rounded-full bg-gray-950 text-gray-50 hover:text-gray-950 hover:bg-gray-200 transition-all duration-300;
}
